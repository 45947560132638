@import "./../node_modules/office-ui-fabric-react/dist/sass/_References.scss";
@import "./../node_modules/office-ui-fabric-core/dist/css/fabric.min.css";

body {
  overflow-x: hidden;
  font-family: "Roboto";
}

label {
  display: block !important;
}

/* Login View*/

.main {
  background-color: #49a9d9;
  background-image: linear-gradient(#000067, #49a9d9, #b8e2fc);
  width: 500px;
  margin: 7em auto;
  border-radius: 1.25em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.sign {
  padding-top: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 23px;
}

.login-error {
  margin-left: 15%;
  font-size: 14px;
}

.custom-login-error {
  margin-bottom: 2.5%;
  font-size: 12px;
}

.un {
  width: 76%;
  color: rgb(38, 50, 56);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 0 20px;
  border: none;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 35px;
  margin-left: 46px;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
}

form.form1 {
  padding: 40px 0;
}

.pass {
  width: 76%;
  color: rgb(38, 50, 56);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 0px 20px;
  border: none;
  outline: none;
  box-sizing: border-box;
  margin: 15px 0 5px 45px;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
}

.passToggle {
  margin: 5px;
}

.submit {
  margin: 5% 0 0 15%;
}

.reset {
  margin: 5% 0 0 10%;
}

.forgot {
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  color: #e1bee7;
  padding-top: 15px;
}

@media (max-width: 600px) {
  .main {
    border-radius: 0px;
  }
}

/* Login View*/
// .ms-Button--primary {
//   background: #2f76c8 !important;
// }

.form-menu .ms-SearchBox {
  border: 0.5px solid #e6e6e6 !important;
  margin-top: 2.5%;
}

.custom-next-prev-pagination {
  display: inline-flex;
  justify-content: space-between;
}

.flex-display {
  display: inline-flex;
}

.ms-DetailsHeader-cellTitle i {
  color: #fff !important;
}

// .ms-Button--primary.is-disabled {
//   background: #f3f2f1 !important;
//   color: #a19f9d !important;
// }

.ms-Panel-headerText,
.fullwidthModal .ms-Dialog-title {
  font-family: "Roboto" !important;
  font-size: 16px !important;
}

.react-bootstrap-table {
  font-family: "Roboto";
  font-size: 14px;
  width: 100%;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.react-bootstrap-table td {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  cursor: pointer;
  white-space: normal;
}

.react-bootstrap-table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #2f76c8 !important;
  color: #eee;
  font-weight: 500;
}

.table-export-button-div {
  float: right;
}

.horizontal-button {
  margin-right: 1%;
}

.cancel-button {
  background: #ef7b7e;

  &:hover {
    background: #cf4449;
    color: #fff;
  }

  border: none;
  color: #fff;
}

.open-panel-button {
  background: #f4b400;

  &:hover {
    background: #c08d02;
    color: #fff;
  }

  border: none;
  color: #fff;
}

.save-button {
  background: #65d36e;

  &:hover {
    background: #29a133;
    color: #fff;
  }

  border: none;
  color: #fff;
}

.other-button {
  background: #dd99ff;

  &:hover {
    background: #d580ff;
    color: #fff;
  }

  border: none;
  color: #fff;
}

.info-button {
  background: #ff5733;

  &:hover {
    background: #c53a1a;
    color: #fff;
  }

  border: none;
  color: #fff;
}

.disable-button {
  background: #f3f2f1;
  border: none;
  color: #a19f9d;
}

.react-bootstrap-table .ms-TooltipHost {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

// .ms-Panel-scrollableContent {
//   height: 48em !important;
// }

.icon-required {
  color: #ff4444;
}

.login-background {
  position: fixed;
  width: 100%;
  top: 0%;
  height: -webkit-fill-available;
  background-color: #cbd9e4;
}

.header-qwikspec {
  // background: linear-gradient(90deg, #f8f8f8 7.5%, #5b8ec7 40%, #2f76c8 100%);
  background: #2f76c8;
  padding: 0.5em 0;
  box-shadow: 2.5px 0px 10px;
  // height: 55px !important;
}

.persona-menu-button {
  color: #fff;
  height: 0px !important;

  &:hover {
    color: #fff;
  }
}

// .header-qwikspec .ms-Persona-details {
//   padding-left: 0px;
// }
.header-qwikspec i {
  display: none !important;
}

.persona-image img {
  left: 50%;
  // position: absolute;
  object-fit: cover;
  top: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
  border-style: none;
  width: 200%;
  height: 5em;
}

// .ms-Callout {
// top: 5em !important;
// right: 2.5em !important;
// }

.circle-text {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  background: #000;
}

.qwikspec-sidebar-collapsed {
  height: 100vh;
  overflow-y: hidden;
  width: 2.5%;
  transition: 0.5s;
  // background: linear-gradient(0deg, rgba(0, 10, 36, 1) 0%, rgba(0, 0, 103, 1) 92%, rgba(0, 212, 255, 1) 100%);
  background: #235489;
}

.qwikspec-sidebar-expanded {
  color: #000;
  height: 100vh;
  transition: 0.5s;
  overflow-y: hidden;
  // background: linear-gradient(0deg, #f8f8f8 2%, #235489 40%, #235489 100%);
  background: #235489;
}

.qwikspec-sidebar-collapsed,
.qwikspec-sidebar-expanded {
  .company-logo {
    position: absolute;
    bottom: 5px;
    width: 75%;
    left: 25%;
    cursor: pointer;
  }

  .menu-item {
    height: 90vh;
    overflow-y: auto;
  }
}

.logo-div {
  display: inline-flex;
  justify-content: space-between;
  padding: 2.5% 1%;
}

.logo-div i {
  color: #fff;
  padding: 5px 2.5px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}

.qwikspec-sidebar-expanded nav,
.qwikspec-sidebar-collapsed nav {
  // height: 92vh !important;
  border: none !important;
  background: transparent !important;
}

.collapse-image {
  &:hover {
    cursor: pointer;
  }
}

.expand-image {
  padding: 5px;
}

.container-collpased {
  width: 97.5%;
  transition: 0.5s;
}

.container-expanded {
  transition: 0.5s;
}

.login-grid {
  margin-top: 7.5%;
}

.login-box-items img {
  width: 75% !important;
}

.login-box-items label {
  color: #fff !important;
}

.header-items {
  color: #fff !important;
  float: right !important;

  &:hover {
    cursor: default;
  }
}

.sub-header-item {
  text-decoration: underline;
  padding-top: 5px;
}

.ms-Persona-primaryText,
.ms-Persona-secondaryText {
  color: #fff !important;
}

.qwikspec-sidebar-expanded a {
  &:hover {
    text-decoration: none;
  }
}

.qwikspec-sidebar-expanded .ms-Image {
  width: 75% !important;
  // height: 50px;
}

.qwikspec-sidebar-expanded .is-disabled,
.qwikspec-sidebar-expanded i {
  font-weight: 700;
  color: #fff;
  height: 2.5em;
  line-height: 2.5em;
}

.qwikspec-sidebar-expanded .ms-Nav-linkText {
  font-family: "Roboto";
  color: #fff !important;
}

.show-file-section {
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.library-menu {
  background-color: #fff !important;
  height: 80vh !important;

  .ms-Button-textContainer {
    .ms-Button-label {
      font-size: 12px;
      width: 250px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }
}

.config-menu {
  height: 82.85vh !important;
}

.config-menu .ms-Nav-compositeLink {
  border-bottom: 0.5px solid #c6c6c6;
  margin: 5px 0;
}

.form-menu .ms-Nav-linkText {
  font-family: "Roboto";
  font-size: 16px;
  color: #235489;
}

.form-menu .is-disabled {
  font-weight: 700;
}

.form-menu .is-selected .ms-Nav-linkText {
  font-weight: 400;
  color: #235489 !important;
  font-weight: 700;
}

.qwikspec-sidebar-expanded .ms-Nav-compositeLink,
.form-menu .ms-Nav-compositeLink {
  &:hover {
    .ms-Nav-linkText {
      color: #000 !important;
    }
  }
}

.qwikspec-sidebar-expanded .is-disabled {
  &:hover {
    .ms-Nav-linkText {
      color: #fff !important;
    }
  }
}

.qwikspec-sidebar-expanded .is-selected .ms-Nav-linkText,
.qwikspec-sidebar-expanded .is-selected i {
  font-weight: 400;
  color: #000 !important;
}

#fb-editor .btn-group button[id$="clear-action"],
#fb-editor .btn-group button[id$="save-action"],
#checkListForm .btn-group button[id$="clear-action"],
#checkListForm .btn-group button[id$="save-action"] {
  display: none !important;
}

.div-form-builder {
  margin-top: 5px;
  max-height: 60vh;
  overflow-y: scroll;
}

.form-menu {
  background: #eeeeee;
  height: 82vh;
}

.form-menu i,
.form-menu button {
  height: 2em !important;
}

.form-menu i {
  line-height: 2em !important;
}

.css-2b097c-container {
  font-size: 12px !important;
}

.css-2613qy-menu {
  z-index: 100000000000 !important;
}

.grid-image-row {
  margin-bottom: 1%;
}

div[class$="-control"] {
  border: 0.5px solid #605e5c !important;
  border-radius: 0px !important;
  min-height: 33px !important;
  height: 33px !important;
}

.asset-site-filter div[class$="-control"] {
  height: unset !important;
}

div[class$="-singleValue"] {
  position: relative !important;
  margin-top: 10px;
}

// .css-yk16xz-control,
// .css-1pahdxg-control {
// }

.css-g1d714-ValueContainer {
  position: initial !important;
}

.lazy-load-div,
.lazy-load-job-div,
.lazy-load-task-div,
.lazy-load-customer-div {
  width: 100%;
  height: 81vh;
  margin-top: 0.25%;
  box-shadow: 2.5px 1px 7.5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.lazy-load-task-div {
  overflow-x: scroll;
}

.lazy-load-div {
  height: 80vh;
}

.lazy-load-double-row-div {
  width: 100%;
  box-shadow: 2.5px 1px 7.5px;
  overflow-y: auto;
  // overflow-x: scroll;
  height: 76.5vh;
}

// .lazy-load-div .react-bootstrap-table {
// height: 78vh;
// overflow: scroll;
// }

// .lazy-load-div .react-bootstrap-table th {
// top: 0;
// position: sticky;
// z-index: 10;
// resize: horizontal;
// }

// .react-bootstrap-table th {
//   resize: horizontal !important;
// }

.infinite-scroll-component {
  overflow: hidden !important;
}

.lazy-load-job-div .react-bootstrap-table-pagination,
.lazy-load-task-div .react-bootstrap-table-pagination,
.lazy-load-div .react-bootstrap-table-pagination,
.lazy-load-double-row-div .react-bootstrap-table-pagination {
  display: none !important;
}

.react-bootstrap-table-pagination .pagination {
  float: right;
}

.react-bootstrap-table-pagination .pagination .page-item.active .page-link {
  background-color: #2f76c8 !important;
}

::-webkit-scrollbar {
  width: 7.5px;
  height: 7.5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

.mandatory-span {
  color: #FF0000;
  font-weight: 700;
}

.warning-span {
  color: #FFA500;
  font-weight: 700;
}

.success-span {
  color: #0f9d58;
  font-weight: 700;
}

.code-details {
  border-bottom: 1px dotted;
  color: #4285f4;
  font-size: 1.25em;
  cursor: default;
}

.info-span {
  color: #4285f4;
  cursor: default;
}

.step-header {
  text-align: center;
}

button {
  outline: none !important;
}

.react-bootstrap-table input {
  padding: 2.5% 2.5% !important;
  border-radius: 0px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.date-picker-input {
  border: 1px solid;
  width: 100%;
  background: #fff;
  height: 33px;
}

.deleted-task-row {
  background: #ff4444;
}

.react-datepicker__input-container input {
  height: 33px;
  cursor: pointer;
}

.task-preview-button-row {
  display: flex;
  margin-bottom: 5px;
}

.task-preview-section button {
  margin-right: 0.25em;
}

.task-preview-section img {
  margin: 0px auto !important;
}

.react-tabs__tab {
  font-size: 15px;
  font-family: "Roboto";
}

.react-tabs__tab--selected {
  background: #4285f4 !important;
  font-weight: 700;
  color: #fff !important;
}

.inactive-toggle-col .ms-Toggle {
  text-align: right;
  margin-top: 7px;
}

.inactive-toggle-col .ms-Label {
  margin: 0 0 0 5px !important;
}

.custom-breadcrumb {
  margin: 0px !important;
}

.custom-breadcrumb button {
  &:hover {
    background: none !important;
  }
}

.breadcrumb-label,
.ms-Breadcrumb-itemLink,
.ms-Breadcrumb-listItem .ms-TooltipHost,
.ms-Breadcrumb-listItem i {
  font-size: 15px !important;
  color: #235489 !important;
}

.breadcrumb-label,
.ms-Breadcrumb-itemLink,
.ms-Breadcrumb-listItem .ms-TooltipHost {
  font-family: "Roboto" !important;
}

.action-span {
  display: flex;
}

.action-span .ms-ChoiceFieldGroup-flexContainer {
  display: flex !important;
}

.ms-ChoiceField {
  margin: 0 5px !important;
}

.ms-Dropdown-title {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

.iframe-class {
  height: 100%;
  position: absolute;
  margin: 5px 10px;
  width: 95%;
}

.iframe-class-dashboard {
  height: 75vh;
  position: absolute;
  margin: 5px 10px;
  width: 95%;
}

.iframe-row {
  height: 75vh;
}

.iframe-report {
  height: 90vh;
  margin: 5px 10px;
  width: 95%;
}

// .config-json-viewer p {
//   font-size: 16px !important;
//   font-weight: 700;
//   color: #ef7b7e !important;
// }

.fullwidthModal .ms-Dialog-main {
  z-index: 99 !important;
  min-width: 95% !important;
  height: 100% !important;
  position: absolute !important;
}

.client-pop-up-label {
  margin-left: 7.5px;
}

.add-client-user-row {
  margin-bottom: 2px;
}

.add-client-toggle {
  margin-top: 5px;
}

.login-spinner-div {
  position: fixed;
  top: 37.5vh;
  left: 42.5vw;
}

.login-spinner-div .ms-Spinner-circle {
  width: 5em !important;
  height: 5em !important;
  border-width: 7.5px !important;
}

.login-spinner-div .ms-Spinner-label {
  font-size: 2.5em !important;
}

.add-extra-pane {
  background: #d5d5d5;
  padding: 10px !important;
}

.add-extra-pane button {
  float: right;
}

.table-responsive {
  overflow-x: initial !important;
}

.asset-main-filter-grid {
  margin-bottom: 10px;
}

.report-grid .k-grid {
  height: 87vh;
}

.asset-grid .k-grid {
  height: 82vh;
}

.asset-grid,
.k-grid-container,
.assessment-grid .k-grid-container {
  font-size: 12px;
}

.k-grid th,
.k-grid td {
  padding: 5px 5px !important;
  color: #000;
}

.k-grid {
  height: 49.5em;
}

.k-header {
  background: #2f76c8 !important;
  font-weight: 500 !important;
  color: #eee !important;
  font-family: "Roboto", sans-serif;
  border-right: 1px solid #fff !important;
}

.k-icon {
  color: #000 !important;
}

.k-grid .k-grid-container {
  height: 62vh;
}

.k-grid .k-grouping-row .no-group-show {
  display: none;
}

.k-grid-toolbar {
  background: transparent !important;
  padding: 0px 8px !important;
}

.no-group-show i {
  &:hover {
    cursor: pointer;
    color: #78c5ef;
  }
}

.ms-DetailsHeader-cellTitle {
  background: #4285f4;
  color: white;
  border-right: 1px solid #fff;
}

.task-dashboard-grid {
  display: flex;
}

.task-dashboard-grid div,
.task-dashboard-grid button {
  margin-right: 2.5px;
}

.ms-Checkbox-label {
  display: flex !important;
}

.ms-DetailsRow-cell {
  font-size: 15px;
}

.react-timekeeper {
  z-index: 1000 !important;
}

.fileContainer {
  display: block !important;
}

.file-list-view {
  margin-bottom: 1%;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
}

#homelink button {
  font-weight: bold !important;
  padding-left: 5px !important;
}

.ms-Dialog-main {
  max-width: none !important;
}

#confirm-task-dialog .ms-Dialog-inner {
  height: 35em !important;
}

.child-checklist-section {
  padding: 5px;
}

.checklist-section,
.checklist-item {
  padding: 5px;
  height: 70vh;
  overflow-y: scroll;
}

.child-checklist-section li,
.checklist-section li,
.checklist-item li {
  list-style: none;
  cursor: pointer;
  margin: 5px 0;
  padding: 2.5px 0;
  font-size: 14px;
}

.child-checklist-section li,
.checklist-section li {
  border-bottom: 1px solid #ccc;

  &:hover {
    background: #e6e6e6;
  }
}

.selected-checklist {
  background-color: #e6e6e6;
}

.section-item {
  border-right: 1px solid #e6e6e6;
}

.item-list-help-div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;

  &:hover {
    cursor: pointer;
    background: #e6e6e6;
  }
}

.item-list-help-div i {
  &:hover {
    cursor: pointer;
  }
}

.shift-asset-modal {
  .ms-Dialog-main {
    width: 750px !important;
    height: 500px !important;
  }

  .ms-Dialog-inner {
    height: 450px !important;
  }
}

.button-right {
  text-align: right;
}

.label-flex {
  display: flex;
  justify-content: space-between;
}

.add-asset-modal {
  .ms-Dialog-main {
    width: 400px !important;
    height: 200px important;
  }

  .ms-Dialog-inner {
    height: 500px important;
  }
}

#remove {
  display: flex;
  justify-content: flex-end;
}

.k-filterable {
  .k-dropdown {
    width: 6.75rem !important;
  }
}

.k-filtercell-wrapper {
  display: block !important;
}

.k-filtercell .k-filtercell-operator>.k-button:not(.k-clear-button-visible) {
  visibility: visible !important;
}

// .k-filtercell .k-filtercell-wrapper > .k-textbox {
// }

.k-filtercell-operator {
  margin-left: 1px !important;
  margin-top: 2.5px !important;
}

.k-filterable .k-dropdown {
  display: block;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.client-license-section {
  height: 42.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.assessment-grid {
  .k-grid {
    height: 35.75em;
  }
}

.flex-display-space-around {
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  color: #2f76c8;
  font-size: 1.5em;
}

.no-text-decoration {
  a {
    text-decoration: none !important;
  }
}

.blank-row {
  height: 20px;
}

.file-box {
  border: 1px solid #2f76c8;
  margin: 2.5%;
  width: 27.5%;

  &:hover {
    cursor: pointer;
  }
}

.file-icon {
  text-align: center;
  font-size: 4.5em;
  height: 2.1em;
}

.file-border {
  border-bottom: 1px solid #59799e;
}

.file-box-menu-icon {
  text-align: right;

  .ms-Icon {
    font-size: 1.25em;
  }
}

.file-box-name {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #000000;
  margin-bottom: 2.5%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-box-size {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #4285f4;
}

.iframe-library {
  width: 100%;
}

.blank-div {
  width: 70%;
}

.custom-login-component {
  background-color: #e6e6e6;
  height: 92vh;
}

.qwikspec-login-container,
.login-container {

  background-image: url("./images/liftmax-new-background.jpg");
  height: 92vh;
  background-repeat: no-repeat;
  background-size: cover;
  // opacity: 0.2;


  .login-label {
    padding: 25px;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 5%;
    color: #fff;
  }

  .custom-login-container-item,
  .login-container-item {
    padding: 25px;
    width: 30vw;
    background: #e8efec;
  }

}

.top-logo {
  background: transparent;
}


.qwikspec-login-container {
  background-image: url("./images/qwikspec-background.jpg");
  height: 100vh;
}

.flex-text-image {
  display: inline-flex;
  background: #fff;
  padding: 0.75% 0.5% 0.75% 0.5%;
  font-size: 16px;
  text-align: center;
  margin: 5% 0 0 0;
}

.custom-file-div {
  input[type="file"] {
    display: none;
  }

  i {
    margin: 2.5px 7.5px;
  }

}



.custom-csv-upload {
  display: inline-block;
  cursor: pointer;
  position: relative;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  border: 1px solid rgb(0, 120, 212);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 2px;
  min-width: 80px;
  height: 32px;
  background-color: rgb(0, 120, 212);
  color: rgb(255, 255, 255);
  user-select: none;
}

/* Custom Table */

.table-section {
  position: relative;

  .ms-Grid-row {
    height: 75vh;
    overflow-y: scroll;
  }
}

#client-inventory-table {
  font-family: "Roboto", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#client-inventory-table td,
#client-inventory-table th {
  border: 1px solid #ddd;
  padding: 5px;
}

#client-inventory-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#client-inventory-table tr:hover {
  background-color: #ddd;
}

#client-inventory-table th {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  text-align: left;
  background-color: rgb(0, 120, 212);
  color: white;
}

.search-box-custom-width {
  width: 125px;
  /* Set the width as needed */
}
.paragraph {
  color: white; /* Change this to the desired color for paragraphs */
}

.anchor {
  color: white; /* Change this to the desired color for anchors */
}